import request from '@/utils/request'

export function queryHeadCash(parameter) {
  return request({
    url: '/integral/member/order/statistics',
    method: 'post',
    data: parameter
  })
}

export function queryCashList(parameter) {
  return request({
    url: '/integral/member/order/page',
    method: 'post',
    data: parameter
  })
}

export function queryCashDetail(parameter) {
  return request({
    url: '/integral/member/order/detail/'+parameter.id,
    method: 'get'
  })
}

export function getMemberOrderDetail(parameter) {
  return request({
    url: '/member/order',
    method: 'get',
    params: parameter
  })
}

export function queryJiangLiCashList(parameter) {
  return request({
    url: '/integral/member/reward/page',
    method: 'post',
    data: parameter
  })
}

export function queryJiangLiAreaList(parameter) {
  return request({
    url: '/integral/member/reward/page/sub',
    method: 'post',
    data: parameter
  })
}

export function queryRewardDetailList(parameter) {
  return request({
    url: '/integral/member/reward/page/detail',
    method: 'post',
    data: parameter
  })
}

export function queryJiangLiCashHeard(parameter) {
  return request({
    url: '/integral/member/reward/statistics',
    method: 'post',
    data: parameter
  })
}

export function queryBrand(parameter) {
  return request({
    url: '/performance/brand/page',
    method: 'get',
    params: parameter
  })
}

export function queryMangerCashList(parameter) {
  return request({
    url: '/integral/member/manage/reward/page',
    method: 'post',
    data: parameter
  })
}

export function queryMangerCashHeard(parameter) {
  return request({
    url: '/integral/member/manage/reward/statistics',
    method: 'post',
    data: parameter
  })
}

export function queryJiangLiCashDetailList(parameter) {
  return request({
    url: '/integral/member/reward/order/page',
    method: 'post',
    data: parameter
  })
}

export function queryJiangLiHeard(parameter) {
  return request({
    url: '/integral/member/reward/order/page/statistics',
    method: 'post',
    data: parameter
  })
}

export function sendRewardBody(parameter) {
  return request({
    url: '/integral/member/reward/send',
    method: 'post',
    data: parameter
  })
}

export function reViewReward(parameter) {
  return request({
    url: '/integral/member/reward/review',
    method: 'post',
    data: parameter
  })
}

export function sendRewardManagerBody(parameter) {
  return request({
    url: '/integral/member/manage/reward/send',
    method: 'post',
    data: parameter
  })
}
//服务审核
export function sendRewardServerBody(parameter) {
  return request({
    url: '/integral/member/center/reward/send',
    method: 'post',
    data: parameter
  })
}

//将推广业绩明细订单标记为删除
export function deleteRewardMark(parameter) {
  return request({
    url: '/integral/member/reward/page/detail/mark/'+parameter.orderId,
    method: 'post',
    data: parameter
  })
}

//管理及服务订单标记为删除
export function deleteManagerMark(parameter) {
  return request({
    url: '/integral/member/manage/reward/statistics/detail/mark/'+parameter.orderId+'?type='+parameter.type,
    method: 'post',
    data: parameter
  })
}

export function queryManagerRewardDetail(parameter) {
  return request({
    url: '/integral/member/manage/reward/statistics/detail',
    method: 'post',
    data: parameter
  })
}
