<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }" style="cursor: pointer;">
        <a-card :loading='loading'  >
          <div class="card_content" >
            <a-row>
              <a-col :span="12" @click="handleOpenDetail()" style="cursor: pointer;"><span class="card_title">未奖励业绩</span></a-col>
              <a-col :span="12" ><span class="card_title">上次已奖励业绩</span></a-col>
            </a-row>
          </div>
          <div class="card_content" >
            <a-row >
              <a-col :span="12" @click="handleOpenDetail()" style="cursor: pointer;"><span class="color-red">{{dashboard.performance}}</span></a-col>
              <a-col :span="12" ><span class="card_title">{{dashboard.lastPerformance}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="12" @click="handleInitial1()" style="margin-top: 30px;font-size: 22px;cursor: pointer;color: #00A0E9">审核</a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="card_title">可用奖积分85%</span></a-col>
              <a-col :span="12"><span class="card_title">兑换积分15%</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="color-red">{{dashboard.cash}}</span></a-col>
              <a-col :span="12"><span class="color-red">{{dashboard.exchangeIntegral}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-descriptions>
                <a-descriptions-item ></a-descriptions-item>
              </a-descriptions>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-descriptions>
                <a-descriptions-item ></a-descriptions-item>
              </a-descriptions>
            </a-row>
          </div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="上月服务奖积分"><span class="color-red">{{dashboard.lastMonthManageTotal}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="本月服务奖积分"><span class="color-red">{{dashboard.thisMonthManageTotal}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="上月可用积分"><span class="color-red">{{dashboard.lastMonthManageCash}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="本月可用积分"><span class="color-red">{{dashboard.thisMonthManageCash}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="上月兑换积分"><span class="color-red">{{dashboard.lastMonthManageExchange}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions>
                  <a-descriptions-item label="本月兑换积分"><span class="color-red">{{dashboard.thisMonthManageExchange}}</span></a-descriptions-item>
                </a-descriptions>
              </a-col>
            </a-row>
          </div>

        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 200px" placeholder="服务中心" />
      </a-form-model-item>

      <a-form-model-item prop='spreaderName'>
        <a-input v-model='form.spreaderName' style="width: 200px" placeholder="推荐人" />
      </a-form-model-item>

      <a-form-model-item prop='servicePresidentName'>
        <a-input v-model='form.servicePresidentName' style="width: 200px" placeholder="分公司简称" />
      </a-form-model-item>

      <!--      <a-form-model-item prop='date'>-->
      <!--        <a-date-picker-->
      <!--          v-model="dateArr"-->
      <!--          :format="[formatType]"-->
      <!--          :show-time="{-->
      <!--            hideDisabledOptions: true-->
      <!--          }"-->
      <!--          :disabled-date="disabledDate"-->
      <!--          @change="dateChange"-->
      <!--        />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item prop='start'>
        <a-range-picker
            v-model="dateArr"
            :format="[formatType, formatType]"
            :show-time="{
                hideDisabledOptions: true
              }"
            :disabled-date="disabledDate"
            @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>

        <span slot='spreaderValue85' slot-scope='text,record'>
          <template>
            {{Math.round(record.spreaderValue*0.85)}}
          </template>
        </span>
        <span slot='spreaderValue15'slot-scope='text,record'>
          <template>
            {{record.spreaderValue-Math.round(record.spreaderValue*0.85)}}
          </template>
        </span>
        <span slot='indirectSpreaderValue85' slot-scope='text,record'>
          <template>
            {{Math.round(record.indirectSpreaderValue*0.85)}}
          </template>
        </span>
        <span slot='indirectSpreaderValue15' slot-scope='text,record'>
          <template>
            {{record.indirectSpreaderValue-Math.round(record.indirectSpreaderValue*0.85)}}
          </template>
        </span>
        <span slot='indirectSpreaderValue2' slot-scope='text,record'>
          <template>
            {{text==''||text==undefined?0:text}}
          </template>
        </span>
        <span slot='indirectSpreaderValue285' slot-scope='text,record'>
          <template>
            {{Math.round(record.indirectSpreaderValue2==''||record.indirectSpreaderValue2==undefined?0:record.indirectSpreaderValue2*0.85)}}
          </template>
        </span>
        <span slot='indirectSpreaderValue215' slot-scope='text,record'>
          <template>
            {{record.indirectSpreaderValue2==''||record.indirectSpreaderValue2==undefined?0:(record.indirectSpreaderValue2-Math.round(record.indirectSpreaderValue2*0.85))}}
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='服务奖积分审核' :destroyOnClose='true' :maskClosable='true' width="40%"
             ok-text='提交审核' cancel-text='暂不审核' @ok='sendReward'
             @cancel='()=>this.deliver1.visible=false'
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>

      <!--      <a-descriptions :column="4">-->
      <!--        <a-descriptions-item label="运营中心">18</a-descriptions-item>-->
      <!--        <a-descriptions-item label="管理奖积分" :span="3">2000</a-descriptions-item>-->
      <!--      </a-descriptions>-->
      <a-descriptions :column="3">
        <a-descriptions-item label="服务中心">{{sumCount}}</a-descriptions-item>
        <a-descriptions-item label="服务积分">{{sumTotal}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions  :column="4" bordered :labelStyle="{paddingLeft:'2px',paddingRight:'2px',backgroundColor:'#fff'}">
        <a-descriptions-item  >
          <span style="font-size: 18px;font-weight: bold">服务中心</span>
        </a-descriptions-item>
        <a-descriptions-item  >
          <span style="font-size: 18px;font-weight: bold">未奖励业绩</span>
        </a-descriptions-item>
        <a-descriptions-item  >
          <span style="font-size: 18px;font-weight: bold">服务积分</span>
        </a-descriptions-item>
        <a-descriptions-item  >
          <span style="font-size: 18px;font-weight: bold">综合比</span>
        </a-descriptions-item>

        <template  v-for="(item, index) in dashboard.auditList" >
          <a-descriptions-item  :key="index+'1'">{{item.presidentMasterName}}</a-descriptions-item>
          <a-descriptions-item  :key="index+'2'">{{item.performance}}</a-descriptions-item>
          <a-descriptions-item  :key="index+'3'">{{item.rewardTotalValue}} </a-descriptions-item>
          <a-descriptions-item  :key="index+'4'">{{item.zhTemp}}</a-descriptions-item>
        </template>

        <template>
          <a-descriptions-item  ><span class="color-red">合计</span></a-descriptions-item>
          <a-descriptions-item  ><span class="color-red">{{this.performanceTotal}}</span></a-descriptions-item>
          <a-descriptions-item  ><span class="color-red">{{this.sumTotal}}</span> </a-descriptions-item>
          <a-descriptions-item  ><span class="color-red">{{this.zongHe}}</span></a-descriptions-item>
        </template>
      </a-descriptions>

    </a-modal>

    <a-modal title='未奖励业绩明细' :destroyOnClose='true' :maskClosable='true'  width="70%" :scroll="{ y: 300 }"
             @cancel='()=>this.deliver4.visible=false'
             :footer="null" :visible='deliver4.visible' :confirmLoading='deliver4.loading'>
      <a-descriptions>
        <a-descriptions-item label="未奖励业绩">{{ dashboard.performance}}</a-descriptions-item>
      </a-descriptions>
      <s-table id="table4" ref='table4' rowKey='id' size='default' :columns='columns4' :scroll="{ y: 900 }" :data='loadData4' :showPagination="false">
            <span slot='memberBianMa' slot-scope='text, record'>
                      <template>
                        <span>
                              {{getUserCode(record.memberType,record.memberLevel)}}
                        </span>
                      </template>
                </span>
        <span slot='spType' slot-scope='text, record'>
                      <template>
                        <span>
                              {{getUserCode(record.spType,record.spLevel)}}
                        </span>
                      </template>
            </span>
        <span slot='action' slot-scope='text, record'>
                      <template>
                        <span>
                              <a @click='handleDeleteRecord(record)'>清除</a>
                        </span>
                      </template>
            </span>
      </s-table>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import moment from "moment";
import {
  deleteManagerMark,
  deleteRewardMark, queryManagerRewardDetail,
  queryMangerCashHeard,
  queryMangerCashList,
  sendRewardManagerBody,
  sendRewardServerBody
} from "@/api/cash";
import {getMemberCash} from "@/api/operator";

const columns = [
  {
    width: 100,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 80,
    title: '服务中心',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 80,
    title: '分公司简称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
  {
    width: 80,
    title: '业绩',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 80,
    title: '同级推荐',
    dataIndex: 'spreaderName',
    scopedSlots: { customRender: 'spreaderName' }
  },
  {
    width: 80,
    title: '服务积分',
    dataIndex: 'spreaderValue',
    scopedSlots: { customRender: 'spreaderValue' }
  },
  {
    width: 80,
    title: '可用积分85%',
    dataIndex: 'spreaderValue85',
    scopedSlots: { customRender: 'spreaderValue85' }
  },
  {
    width: 80,
    title: '兑换积分15%',
    dataIndex: 'spreaderValue15',
    scopedSlots: { customRender: 'spreaderValue15' }
  },

]

const columns1 = [
  {
    width: 80,
    title: '服务中心',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 80,
    title: '未奖励业绩',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '管理奖积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
]

const columns4 = [
  {
    width: 80,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 80,
    title: '下单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 70,
    title: '订单金额',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 70,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 50,
    title: '购买编码',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberBianMa' }
  },
  {
    width: 80,
    title: '购买人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 80,
    title: '业绩归属人',
    dataIndex: 'spName',
    scopedSlots: { customRender: 'spName' }
  },
  {
    width: 60,
    title: '归属人编码',
    dataIndex: 'spType',
    scopedSlots: { customRender: 'spType' }
  },
  {
    width: 70,
    title: '上级运营',
    dataIndex: 'presidentName',
    scopedSlots: { customRender: 'presidentName' }
  },
  {
    width: 80,
    title: '分公司简称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
  {
    width: 80,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);



export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading: true,
      dashboard: {}, //表头用户数统计
      sumTotal:0,//积分汇总统计
      zongHe:0,//综合占比统计
      performanceTotal:0,//未奖励业绩统计
      sumCount:0,//汇总
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: moment(baseStart).startOf('day').format(formatType )+" 00:00:00",
        end: moment(baseEnd).endOf('day').format(formatType)+" 23:59:59",
      },
      // 表头
      columns,
      columns1,
      columns4,
      // 加载数据方法 必须为 Promise 对象
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        // parameter.member = '24030600004';
        let params = Object.assign(parameter,this.queryParam, this.deliver1.form);
        return getMemberCash(params).then(datum => {

          return datum
        })
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.form);
        params.type = 'center';//表示服务奖
        return queryMangerCashList(params)
            .then(datum => {
              return datum
            })
      },
      loadData4: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.deliver4.form,{type:'center'});
        debugger;
        params.size = 999;
        return queryManagerRewardDetail(params).then(datum => {
          this.$previewRefresh()
          return {
            records: datum
          }
        })
      },
      deliver1: {
        visible: false,
        form: {
        },
        loading: false
      },
      deliver4: {
        visible: false,loading: false, form: {},
        totalCount:0,
      },
    }
  },
  created() {
    this.getHeaderData();
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType)+" 00:00:00" : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType)+" 23:59:59" : "";
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      this.loading=true;
      this.getHeaderData();
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType)+" 00:00:00";
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType)+" 23:59:59";
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    getHeaderData(){
      let params = Object.assign({}, this.form);
      params.type = 'center';//表示管理奖
      queryMangerCashHeard(params).then(result =>{
        this.dashboard = result;
        this.loading = false;
        let sumTotal = 0;
        let sumCount = 0;
        let performanceTotal = 0;
        let zongHe = 0;

        let tempList = [];
        for(let item of result.auditList){
          let per = item.performance>0;
          if(per){
            sumTotal = sumTotal + item.rewardTotalValue;
            performanceTotal = performanceTotal + item.performance;
            sumCount++;
            item.zhTemp = this.liangWei(item.rewardTotalValue, item.performance)+"%";
            tempList.push(item);
          }
        }


        this.zongHe =  this.liangWei(sumTotal, performanceTotal)+"%";
        this.dashboard.auditList = tempList;
        this.sumTotal = sumTotal;
        this.performanceTotal = performanceTotal;
        this.sumCount = sumCount;
      });
    } ,
    liangWei(s1,s2){
      if(s1 ==0 || s2 == 0){
        return 0
      }
      let temp1 = s1/s2;
      let zhTemp1 = Math.round(temp1*1000)/1000;
      let tt = zhTemp1 *100;
      let aa = Math.round(tt*1000)/1000;
      return aa;
    },
    handleInitial1() {
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    //提交审核
    sendReward() {
      this.deliver1.loading = true;
      sendRewardServerBody({}).then(result =>{
        this.deliver1.loading = false;
        this.deliver1.visible=false;
        // debugger;
        this.$message.success('操作成功');
        this.$refs.table.refresh(true)
        this.getHeaderData();
      });
    } ,
    //品牌公司打开推广业绩明细
    handleOpenDetail(){


      this.deliver4.loading = true;
      // this.$refs.table4.refresh(true);

      this.deliver4.visible = true;
    },
    getUserCode(type,level){
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    //标记删除记录
    handleDeleteRecord(record){

      let params = Object.assign({orderId:record.orderId,type:'center'});

      this.deliver4.loading = true;
      deleteManagerMark(params).then(result =>{
        this.$message.success('操作成功');
        this.$refs.table4.refresh(true)
      });
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-descriptions-row > th {
  //width: 1% !important;
  padding: 0 0 !important;
  display: none;
}

/deep/.ant-descriptions-row > td {
  padding-bottom: 10px !important;
  //width: 10% !important;
}


/deep/.ant-page-header{
  display: none;
}

</style>